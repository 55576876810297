import React from 'react'
import PropTypes from 'prop-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { BLOCKS } from '@contentful/rich-text-types'
import { RichText } from 'src/components/Contentful/atoms/RichText'
import { BackorderContainer } from './styles'

const Backorder = ({ color, content }) => {
  const richTextContent =
    renderRichText(content)?.[0]?.props?.children?.[0] !== 'NONE' ? content : ''
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        // eslint-disable-next-line react/destructuring-assignment
        const { file, title } = node.data.target.fields

        return (
          <a href={file} target="_blank" rel="noopener noreferrer">
            {title}
          </a>
        )
      },
    },
  }

  return (
    <BackorderContainer color={color}>
      <RichText content={richTextContent} options={options} />
    </BackorderContainer>
  )
}
Backorder.propTypes = {
  color: PropTypes.string,
  content: PropTypes.shape({
    raw: PropTypes.string,
  }),
}

export default Backorder
