import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'

import { ShippingContainer, ShippingOption, ShippingImageContainer, ShippingText } from './styles'

const ShippingDetails = ({ frame, isCalMax }) => {
  const { fastclock, clock, undo } = useStaticQuery(graphql`
    query ShippingIconsQuery {
      fastclock: file(relativePath: { eq: "fastclock.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      clock: file(relativePath: { eq: "clock.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      undo: file(relativePath: { eq: "undo.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
  `)
  const SHIPPING_INFO = [
    {
      image: clock,
      alt: 'clock',
      text: 'Standard delivery in 5-7 days',
      frameText: 'Standard delivery in 5-7 days',
    },
    {
      image: fastclock,
      alt: 'speedy clock',
      text: 'Expedited delivery in 3-5 days',
    },
    {
      image: undo,
      alt: 'undo arrow',
      text: '120-day free returns',
    },
  ]
  const filteredShippingInfo = isCalMax
    ? SHIPPING_INFO.filter(({ alt }) => alt !== 'clock' && alt !== 'undo arrow')
    : SHIPPING_INFO

  return (
    <ShippingContainer frame={frame}>
      {filteredShippingInfo.map((option, i) => (
        <ShippingOption frame={frame} key={i}>
          <ShippingImageContainer>
            <GatsbyImage image={option.image.childImageSharp.gatsbyImageData} alt={option.alt} />
          </ShippingImageContainer>
          <ShippingText special={option.alt === 'checkmark'}>
            {option.frameText || option.text}
          </ShippingText>
        </ShippingOption>
      ))}
    </ShippingContainer>
  )
}

export default ShippingDetails

ShippingDetails.propTypes = {
  frame: PropTypes.bool,
  isCalMax: PropTypes.bool,
}
